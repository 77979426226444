import { Component } from '@angular/core';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {DisableButtonOnRequestDirective} from "@shared/directives/disable-button-on-request.directive";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-contact-us-alert',
  standalone: true,
  imports: [MatDialogModule, DisableButtonOnRequestDirective, MatButton],
  templateUrl: './contact-us-alert.component.html',
  styleUrl: './contact-us-alert.component.scss'
})
export class ContactUsAlertComponent {
  constructor(private dialogRef: MatDialogRef<ContactUsAlertComponent>) {}

  closeDialog(): void {
    this.dialogRef.close(); // Close the dialog
  }

}
