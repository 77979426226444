import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactUsAlertComponent } from '@shared/components/contact-us-alert/contact-us-alert.component';
@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private dialog: MatDialog, private _snackBar: MatSnackBar) { }

  successAlert(text: string = 'Operation Success') {
    this.openSnackBar(text, 'success');
  }

  failedAlert(text: string = 'Operation Failed') {
    this.openSnackBar(text, 'failed');
  }

  unauthorizedAlert(text: string = 'unauthorized') {
    this.openSnackBar(text, 'failed');
  }

  serverErrorAlert(text: string = 'Server Error') {
    this.openSnackBar(text, 'failed');
  }

  openSnackBar(text: string, type: string) {
    this._snackBar.open(text, '', {
      panelClass: type,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: 3000
    })
  }

  contactUs() {
    this.dialog.open(ContactUsAlertComponent)
  }

}
